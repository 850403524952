import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/views/Layout'

import Organizationsapi from '@/services/api/organizations'
//import store from '@/store'
import cookie from 'vue-cookie'


import auth from '@/cognito.js';
import Signinapi from '@/services/api/signin'

Vue.use(Router)

var redirectToOrg = function(to,from, next, name){
      
      var orgkey = to.params.orgname
      var helppage = ""
      
      //var orgkey2 = cookie.get('organizationkey');
      //console.log( orgkey, "zzzz1111111111111")
      //console.log( orgkey, name, "redirectToOrg")

     // alert(orgkey2)

      //var userorganizationkey = store.getters.user.organization
      
      //var userorganizationkey = $route.params.orgname
      //var token = store.getters.token
      //var orgkey = store.state.organizations.selectedorganizations.key ? store.state.organizations.selectedorganizations.key : userorganizationkey.key;
      //alert(name)



      if(name == "helppage" ){
        helppage = to.path
      }

      

      var token = cookie.get('usertoken');
     // var token = store.getters.token

      //let activeorgkey = ""

      if( orgkey == "projects" || orgkey == "boards" || name == "organizations" || helppage != "" ){
    

        Organizationsapi.GetActiveorg( token ).then( function(response){        
          if( response.data.ok ){           
            if(response.data.org && response.data.org.key){


              if( name == "organizations" ){
                next("/"+response.data.org.key+"/organizations")
              }


               if( orgkey == "boards" ){
                next("/"+response.data.org.key+"/boards")
               }

               if( orgkey == "projects" ){
                next("/"+response.data.org.key+"/projects")
               }

               if( name == "helppage" ){
                 next("/"+response.data.org.key+"/helppage")
                //location.href="/"+response.data.org.key + helppage
               }

               
            }
          }else{
              cookie.delete('organizationkey');
              location.href='/signout'
          }
        })

        //if( orgkey2)

        //next()
        //alert('')
        //next("/myorg")
        //location.href="/"

      }else{

       // alert('b')

        if( orgkey ){


          Organizationsapi.Details( token, orgkey ).then( function(response){   
            if( response.data.organization && response.data.organization.withaccess == false ){
              cookie.delete('organizationkey');
              //next({name: name, params:{orgname: orgkey}})
              next("/400")
              // next()
            }else{
              next()
            }
          }).catch( function( ){
             //cookie.delete('organizationkey');
             next("/403")
          })



        }else{

          next()
        }

        

      }




      /*if( orgkey ){

        next({name: name, params:{orgname: orgkey}})
       
      }else{

        next('/')

      }*/
}
const organizationsRoutes = [

  /*{
    path:'/:orgname/profile',
    name:'redirectprofile',
    meta:{title:'Boards'}
  },*/

  /*{
    path:'/:orgname',
    name:'redirectorganizations',
    meta:{title:'Boards'},
    beforeEnter( to, from, next){
        redirectToOrg(to, from, next,'boardsoverview')
    }
  },*/

  /*{
    path:'/boards',
    name:'redirectboards',
    meta:{title:'Home'},
    beforeEnter( to, from, next){

      console.log( to, from, next )

        //redirectToOrg(to, from, next,'boardsoverview')
    }
  },*/

  {
    path:'/profile',
    name:'redirectprofile',
    meta:{title:'My profile'},
    beforeEnter( to, from, next){
        redirectToOrg(to, from, next,'organizationsoverview')
    }
  },


  {
    path:'/newsfeed',
    name:'redirectnewsfeed',
    meta:{title:'Newsfeed'},
    beforeEnter( to, from, next){
        redirectToOrg(to, from, next,'organizationsoverview')
    }
  },


  {
    path:'/help/:helppage',
    name:'redirecthelp',
    meta:{title:'Help'},
    beforeEnter( to, from, next){


     // console.log( to, from, next , "ssssssssssss")
     // alert('')
        redirectToOrg(to, from, next,'helppage')
    },
    //components:{
    //  default: () => import('@/views/Layout/components/helpContent')
    //},
  },

  {
    path: '/confirmdeactiveaccount',
    meta: { title: 'Confirm deactiveaccount' },
    name: 'confirmdeactiveaccount',
    components:{auth: () => import('@/views/auth/deactivate')}
  },


  {
    path:'/verify',
    name:'verify',
    meta:{title:'Verify account to proceed'},
    components:{
      layout:Layout,
      default: () => import('@/views/auth/verify')
    },
  },


  

  {
    path:'/:orgname/deleteaccount',
    name:'deleteaccount',
    meta:{title:'Review details to proceed'},
    components:{
      layout:Layout,
      default: () => import('@/views/auth/review')
    },
  },

  {
        path:'/:orgname/objectives',
        name:'objectiveovervieworgs',
        meta:{title:'Objective overview', navindex: "objectives"},
       /* beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'boardsoverview')
        },*/
        components:{
          layout:Layout,
          default: () => import('@/views/cockpit')
        },
        /*children: [

        ]  */
  },
/*
  {
        path:'/:orgname/portfolio',
        name:'portfolioovervieworgs',
        meta:{title:'Portfolio overview', navindex: "portfolio"},
        components:{
          layout:Layout,
          default: () => import('@/views/cockpit')
        },
  },

  {
        path:'/:orgname/portfolio/:portfolioid/detail',
        name:'portfoliooverviewdetail',
        meta:{title:'Portfolio detail', navindex: "portfolio"},
        components:{
          layout:Layout,
          default: () => import('@/views/cockpit')
        },

  },*/

  {
    path:'/organizations',
    name:'redirectorganizations',
    meta:{title:'My profile'},
    beforeEnter( to, from, next){
        redirectToOrg(to, from, next,'organizations')
    }
  },

  {
    path:'/:orgname',
    name:'mainorg',
    meta: { title:'Home', requiresAuth: true },
    components:{
     layout:Layout,
    },
    beforeEnter( to, from, next){

       redirectToOrg(to, from, next,'projectsoverview')
    
      /*var Nkey = to.params.orgname
      //if( Nkey != "" ){
      //    next()
      //} 

      var token = store.getters.token

      Organizationsapi.Details( token, Nkey ).then( function(response){        
        if( response.data.organization && response.data.organization.withaccess ){
          next()
        }else{
          next("400")
        }
      })*/



    },

    children:[
      
      {
        path:'boards',
        name:'boardsoverview',
        meta:{title:'Board view', navindex: "board"},
        components:{default: () => import('@/views/dashboard') },
        beforeEnter( to, from, next ){
          //alert('')
          redirectToOrg(to, from, next,'boardsoverview')
        }

      },

      {
        path:'projects',
        name:'projectsoverview',
        meta:{title:'Projects view', navindex: "project"},
        components:{default: () => import('@/views/dashboard') },
        beforeEnter( to, from, next ){
          //alert('')
          redirectToOrg(to, from, next,'projectsoverview')
        }

      },

      {

        path:'boards/:cid',
        name:'boardvieworg',
        meta:{title:'Board view', navindex: "board"},
         components:{
          default: () => import('@/views/cockpit')
        },
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'boardsoverview')
        },
        children:[

        ]
      },

      {

        path:'projects/:cid',
        name:'projectvieworg',
        meta:{title:'Project view', navindex: "project"},
         components:{
          default: () => import('@/views/cockpit')
        },
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'projectsoverview')
        },
        children:[

        ]
      },


      {
        path:'boards/:cid/statistics',
        name:'boardstatistics',
        meta:{title:'Board statistics', navindex: "board"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'boardsoverview')
        },
        components:{
          default: () => import('@/views/cockpit')
        },

      },

      {
        path:'projects/:cid/statistics',
        name:'projectstatistics',
        meta:{title:'Project statistics', navindex: "project"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'projectsoverview')
        },
        components:{
          default: () => import('@/views/cockpit')
        },

      },

      {
        path:'boards/:cid/objectives',
        name:'projectoverview',
        meta:{title:'Board overview', navindex: "board"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'boardsoverview')
        },
        components:{
          default: () => import('@/views/cockpit')
        },

      },

      {
        path:'projects/:cid/objectives',
        name:'projectoverview',
        meta:{title:'Project overview', navindex: "project"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'projectsoverview')
        },
        components:{
          default: () => import('@/views/cockpit')
        },

      },

   
      {
        path:'boards/:cid/board',
        name:'projectoverview3',
        meta:{title:'Board overview', navindex: "board"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'boardsoverview')
        },
        components:{
          default: () => import('@/views/cockpit')
        },

      },

      {
        path:'projects/:cid/project',
        name:'projectoverview3',
        meta:{title:'Project overview', navindex: "project"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'projectsoverview')
        },
        components:{
          default: () => import('@/views/cockpit')
        },

      },

      {
        path:'boards/:cid/stakeholders',
        name:'boardstakeholders',
        meta:{title:'Board stakeholders', navindex: "board"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'boardsoverview')
        },
        components:{
          default: () => import('@/views/cockpit')
        },

      },

      {
        path:'projects/:cid/stakeholders',
        name:'projectstakeholders',
        meta:{title:'Project stakeholders', navindex: "project"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'projectsoverview')
        },
        components:{
          default: () => import('@/views/cockpit')
        },

      },


      {
        path:'boards/:cid/riskmanagement',
        name:'boardriskmanagement',
        meta:{title:'Board risk management', navindex: "board"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'boardsoverview')
        },
        components:{
          default: () => import('@/views/cockpit')
        },

      },

      {
        path:'projects/:cid/riskmanagement',
        name:'projectriskmanagement',
        meta:{title:'Project risk management', navindex: "project"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'projectsoverview')
        },
        components:{
          default: () => import('@/views/cockpit')
        },

      },


      {
        path:'boards/:cid/masterplan',
        name:'boardmasterplan',
        meta:{title:'Board risk master plan', navindex: "board"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'boardsoverview')
        },
        components:{
          default: () => import('@/views/cockpit')
        },

      },


      {
        path:'projects/:cid/masterplan',
        name:'projectmasterplan',
        meta:{title:'Project risk master plan', navindex: "project"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'projectsoverview')
        },
        components:{
          default: () => import('@/views/cockpit')
        },

      },


      {
        path:'boards/:cid/newsletters',
        name:'boardnewsletter',
        meta:{title:'Board newsletter', navindex: "board"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'boardsoverview')
        },
        components:{
          default: () => import('@/views/cockpit')
        },

      },


      {
        path:'projects/:cid/newsletters',
        name:'projectnewsletter',
        meta:{title:'Project newsletter', navindex: "project"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'projectsoverview')
        },
        components:{
          default: () => import('@/views/cockpit')
        },

      },


      {
        path:'boards/:cid/reports',
        name:'boardreports',
        meta:{title:'Board reports', navindex: "reports"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'boardsoverview')
        },
        components:{
          default: () => import('@/views/cockpit')
        },

      },

      {
        path:'projects/:cid/reports',
        name:'projectreports',
        meta:{title:'Project reports', navindex: "reports"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'projectsoverview')
        },
        components:{
          default: () => import('@/views/cockpit')
        },

      },


      {
        path:'boards/:cid/reportsedit',
        name:'boardreportsedit',
        meta:{title:'Board reports', navindex: "reportsedit"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'boardsoverview')
        },
        beforeRouteLeave( to, from, next ){
          console.log( to, from, next )
        },
        components:{
          default: () => import('@/views/cockpit')
        },

      },

      {
        path:'projects/:cid/reportsedit',
        name:'projectreportsedit',
        meta:{title:'Project reports', navindex: "reportsedit"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'projectsoverview')
        },
        beforeRouteLeave( to, from, next ){
          console.log( to, from, next )
        },
        components:{
          default: () => import('@/views/cockpit')
        },

      },


      {
        path:'boards/:cid/reportsview',
        name:'boardreportsview',
        meta:{title:'Board reports view', navindex: "reportsview"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'boardsoverview')
        },
        beforeRouteLeave( to, from, next ){
          console.log( to, from, next )
        },
        components:{
          default: () => import('@/views/cockpit')
        },

      },

      {
        path:'projects/:cid/reportsview',
        name:'projectreportsview',
        meta:{title:'Project reports view', navindex: "reportsview"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'projectsoverview')
        },
        beforeRouteLeave( to, from, next ){
          console.log( to, from, next )
        },
        components:{
          default: () => import('@/views/cockpit')
        },

      },


      {
        path:'boards/:cid/builder',
        name:'boardsaibuilder',
        meta:{title:'Boards ai builder', navindex: "boardai"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'boardsoverview')
        },
        components:{
          default: () => import('@/views/tools/aibuilder')
        },

      },

      {
        path:'projects/:cid/builder',
        name:'projectsaibuilder',
        meta:{title:'Projects ai builder', navindex: "projectai"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'projectsoverview')
        },
        components:{
          default: () => import('@/views/tools/aibuilder')
        },

      },

      {
        path:'boards/:cid/items',
        name:'boarditems',
        meta:{title:'Board items', navindex: "boarditems"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'boardsoverview')
        },
        components:{
          default: () => import('@/views/cockpit')
        },

      },


      {
        path:'projects/:cid/items',
        name:'projectitems',
        meta:{title:'Project items', navindex: "projectitems"},
        beforeEnter( to, from, next ){
          redirectToOrg(to, from, next,'projectsoverview')
        },
        components:{
          default: () => import('@/views/cockpit')
        },

      },


      {
        path:'portfolios',
        name:'portfolios',
        meta:{title:'My portfolio', navindex:"portfolio"},
        components:{
          default: () => import('@/views/portfolio/portfolio')
        },
      },
      {
        path:'portfolio/:portfolioid/detail',
        name:'portfoliodetail',
        meta:{title:'My portfolio view', navindex:"portfolio"},
        components:{
          default: () => import('@/views/portfolio/portfoliodetail')
        },
      },
      {
        path:'portfolio/:portfolioid/settings',
        name:'portfoliosettings',
        meta:{title:'My portfolio settings', navindex:"portfolio"},
        components:{
          default: () => import('@/views/portfolio/portfolioSettings')
        },
      },
      {
        path:'profile',
        name:'profile',
        meta:{title:'My profile', navindex:"account"},
        components:{
          default: () => import('@/components/profile/myprofile')
        },
      },
      {
        path:'help',
        name:'help',
        meta:{title:'Help', navindex:"help"},
        components:{
          default: () => import('@/views/Layout/components/helpContent')
        },
      },
      {
        path:'help/:helppage',
        name:'help detail',
        meta:{title:'Help2', navindex:"help2"},
        components:{
          default: () => import('@/views/Layout/components/helpContentview')
        },
      },
      {
        path:'help/:helppage/:helpsubpage',
        name:'help detail 2',
        meta:{title:'Help3', navindex:"help3"},
        components:{
          default: () => import('@/views/Layout/components/helpContentview')
        },
      },
      {
        path:'help2',
        name:'help2',
        meta:{title:'Help 2', navindex:"help2"},
        components:{
          default: () => import('@/views/Layout/components/helpContent2')
        },
      },
      {
        path:'newsfeed',
        name:'newsfeed',
        meta:{title:'Newsfeed', navindex:"newsfeed"},
        components:{
          default: () => import('@/components/newsfeed/newsfeedpages')
        },
      },

      {
        path:'organizations',
        name:'organizationsoverview',
        meta:{title:'Organizations view', navindex:"organizations"},
        components:{default: () => import('@/views/organizations/organizationsoverview') }

      },

      {
        path:'organizations',
        name:'organizations',

        meta:{title:'Organizations', navindex:"organizations"},

        components:{
          default: () => import('@/views/organizations')
        },
        children:[
          {
            path:"about",
            name:"aboutorganizations",

            meta:{title:'About', navindex:"organizations"},


            components:{ organizations: () => import('@/views/organizations/components/about')}
          },
           {
            path:"members",
            name:"membersorganizations",

             meta:{title:'Members', navindex:"organizations"},



            components:{ organizations: () => import('@/views/organizations/components/members')}
          },
           {
            path:"teams",
            name:"teamsorganizations",
             meta:{title:'Teams', navindex:"teams"},
            components:{ organizations: () => import('@/views/organizations/components/teams')}
          },
          {
            path:"teams/:teamid",
            name:"teamsorganizationsbyid",
            meta:{title:'Team', navindex:"teamsbyid"},
            components:{ organizations: () => import('@/views/organizations/components/teams')}
          }
        ]
      },
    ]
  },
]
  
export let constantRouterMap = [
  {
    path: '/set-password',
    meta: { title: 'Set Password' },
    name: 'setpassword',
    components:{auth: () => import('@/views/auth/changepassword')}
  },


  {
    path: '/sign-out',
    meta: { title: 'Sign out' },
    name: 'signout',
    components:{auth: () => import('@/views/auth/signout')},
    /*beforeEnter( to, from, next){
      console.log( to, from, next )

      alert('')
    }*/
  },
  {
    path: '/',
    meta: { title: 'Flowe' },
    name: 'home page',
  },
  {
    path: '/sign-in',
    meta: { title: 'Sign in' },
    name: 'signin page with redirect',
    components:{auth: () => import('@/views/auth/signin')}
  },
  {
    path: '/ssosignin',
    meta: { title: 'Sign in' },
    name: 'sso signin endpoint',
    beforeEnter( to, from, next){

        //this.$store.dispatch('fechtCockpit',{cid:cid, orgkey: this.orgkey, token: this.token})
        // before sign in
        console.log( to, from, next )

        console.log( to.query.code, to.query.state)

        //Signinapi.validateAuthcode(  to.query.code ).then( function(response){
        //  console.log(to.query.code, response, "zxcxzc")
        //})

        var currUrl = window.location.href;
        auth.auth.useCodeGrantFlow()
        auth.auth.parseCognitoWebResponse(currUrl);          
      
        //var currUrl = window.location.href;
        //auth.auth.parseCognitoWebResponse(currUrl);

        //next("/boards")
    }
  },
  {
    path: '/ssosignout',
    meta: { title: 'Sign out' },
    name: 'signout page with sso',
    components:{auth: () => import('@/views/auth/signoutconfirm')}
    /*beforeEnter( to, from, next){

      console.log( to, from, next )
      alert()
    }*/
  },
  {
    path: '/sign-in-sso',
    meta: { title: 'Sign in sso' },
    name: 'signin page with sso',
    components:{auth: () => import('@/views/auth/signinsso')}
  },
  {
    path: '/sign-in-sso/:idp',
    meta: { title: 'Sign in sso idp' },
    name: 'signin page with sso tru idp',
    beforeEnter( to, from, next){
        // before sign out
        console.log( to, from, next )

        if (!auth.auth.isUserSignedIn()) {

          var idp = to.params.idp

          //let self = this

          Signinapi.Loadidp( idp ).then( function(response){

            location.href=response.data.idp.idp
            //console.log( response.data )

          })


        }

        //next(false)
    }
  },
  {
    path: '/sign-out-sso',
    meta: { title: 'Sign out sso' },
    name: 'signin out sso',
    components:{auth: () => import('@/views/auth/signoutsso')}
  },
  {
    path: '/sign-in-relay',
    meta: { title: 'Sign in relay' },
    name: 'signin relay',
    components:{auth: () => import('@/views/auth/signinrelay')}
  },
  {
    path: '/forgot-password',
    meta: { title: 'Forgot password' },
    name: 'forgot password page',
    components:{auth: () => import('@/views/auth/forgotpassword')}
  },
  {
    path: '/password-reset',
    meta: { title: 'Reset password' },
    name: 'reset password page',
    components:{auth: () => import('@/views/auth/changepassword')}
  },
  {
    path: '/email-change',
    meta: { title: 'Change email' },
    name: 'change email confirm page',
    components:{auth: () => import('@/views/auth/changeemail')}
  },
  {
    path: '/sign-up',
    meta: { title: 'Sign up ' },
    name: 'sign up page',
    components:{auth: () => import('@/views/auth/signup')}
  },
  {
    path: '/timed-out',
    meta: { title: 'Time out' },
    name: 'timed out',
    components:{auth: () => import('@/views/auth/timeout')}
  },
  {
    path: '/sign-in',
    meta: { title: 'Sign in' },
    name: 'sign in page',
    components:{auth: () => import('@/views/auth/signin')}
  },

  {
    path: '/deactivatemyaccount',
    meta: { title: 'Deactivate my account' },
    name: 'deactivate my account',
    components:{auth: () => import('@/views/auth/deactivate')}
  },
  { 
    path: '/registration',
    meta: { title: 'Create your account' },
    name: 'registration',
    components:{auth: () => import('@/views/auth/register')}
  },

  {
     path: '/tools-pages',
     meta: { title: 'Tools' },
     name: 'tools',
     components:{auth: () => import('@/views/tools/items')}
   },

  {
    path:'/apitoken',
    name:'apitoken',
    meta:{title:'Rest callback'},
    components:{
      default: () => import('@/views/auth/apitoken')
    },
  },

   {
     path: '/masterplan1',
     meta: { title: 'Tools' },
     name: 'tools',
     components:{auth: () => import('@/views/tools/gantt1')}
   },

   {
     path: '/masterplan2',
     meta: { title: 'Tools' },
     name: 'tools',
     components:{auth: () => import('@/views/tools/gantt2')}
   },

   {
     path: '/masterplan3',
     meta: { title: 'Tools' },
     name: 'tools',
     components:{auth: () => import('@/views/tools/gantt3')}
   },

  {
    path:'/error-pages',
    meta: {title: '404 error'},
    name: '404 error pages',
    components:{auth: () => import('@/views/errors/404')}

  },

  {
    path:'/400',
    meta: {title: '400 error'},
    name: 'error 400',

    components:{auth: () => import('@/views/errors/400')}

  },

  {
    path:'/401',
    meta: {title: '401 error'},
    name: 'error 401',
    components:{auth: () => import('@/views/errors/401')}

  },

  {
    path:'/402',
    meta: {title: '402 error'},
    name: 'error 402',

    components:{auth: () => import('@/views/errors/402')}

  },

  {
    path:'/403',
    meta: {title: '403 error'},
    name: 'error403',
    components:{auth: () => import('@/views/errors/403')}
    
  },

  {
    path:'/404',
    meta: {title: '404 error'},
    name: 'error 404',
    components:{auth: () => import('@/views/errors/404')}

  },

  {
    path:'/405',
    mete: {title: '405 error'},
    name: 'error 405',
    components:{auth: () => import('@/views/errors/405')}
    
  },
  /* page tests*/
  // 
]

// const routesfortesting = [
//   { 
//    path:'/avatarimages',
//    name:'avatarimages',
//    meta:{title:'avatar images'},
//    components:{
//     default: () => import('@/views/tools/avatarimages')
//   },

//   }, 
//   {
//     path:'/profiler',
//     mete: {title: 'profiler'},
//     name: 'editor test',
//     components:{auth: () => import('@/views/tools/profiler')}
//   },
//   {
//     path: '/tools-pages',
//     meta: { title: 'Pick a user to sign in with' },
//     name: 'sign in a user',
//     components:{auth: () => import('@/views/auth/pick')}
//   },
//   {
//     path: '/sandbox',
//     meta: { title: 'sandbox' },
//     name: 'sandbox',
//     components:{auth: () => import('@/views/auth/sandbox')}
//   },
//   {
//     path:'/inputfile',
//     name:'inputfile',
//     meta:{title:'Newsfeed'},
//     components:{
//       default: () => import('@/views/tools/inputfile')
//     },
//   },
// ]

// constantRouterMap = constantRouterMap.concat(routesfortesting)
constantRouterMap = constantRouterMap.concat(organizationsRoutes)
constantRouterMap.push({
    path:'*',
    mete: {title: '404 error'},
    name: '404 error all',
    components:{auth: () => import('@/views/errors/404')}
  })

export default new Router({
  mode: 'history',
  hash: true,
  routes: constantRouterMap
})


