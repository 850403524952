import router from '@/router'
import store from '@/store'

import cookie from 'vue-cookie'

//import authstore from '@/store/cognito-info-store';
//import auth from '@/cognito.js';

//import Organizationsapi from '@/services/api/organizations'

var errpages = store.state.errpages
var whiteListAuth = store.state.whiteListAuth
var whiteList = store.state.whiteList

router.beforeEach((to, from, next) =>{

	let levs = to.path.split('/')

	var usertoken = cookie.get('usertoken'); 
	let organizationkey = cookie.get('organizationkey')


	if( to.fullPath.indexOf('sign-in') > -1 || to.fullPath.indexOf('sign-up') > -1 || to.fullPath.indexOf('registration') > -1 || to.fullPath.indexOf('password-reset') > -1 ){
		next()
	}else{

		if( !usertoken && !organizationkey && to.name != 'forgot password page' ){
			router.push('/sign-in')
		}

	}


	if( usertoken && organizationkey != '' ){

		if( to.fullPath == '/' ){
			cookie.set('showorghelp', "yes");
			next('/' + organizationkey + '/projects')
		}

		if( to.fullPath == '/?redirect=organizations' ){
			next('/' + organizationkey + '/organizations')
		}

		if( levs[1] && levs[1] == 'boards' && !levs[2] ){
			next('/' + organizationkey + '/boards')
		}

		if( levs[1] && levs[1] == 'projects' && !levs[2] ){
			console.log('mmmmmmmmmmm')
			next('/' + organizationkey + '/projects')
		}

		if( levs[1] && levs[1] == 'objectives' && !levs[2] ){
			next('/' + organizationkey + '/objectives')
		}

		if( levs[1] && levs[1] == organizationkey && !levs[2] ){
			next('/' + organizationkey + '/projects')
		}

		if( levs[1] && levs[1] == 'organizations' && !levs[2] ){
			next('/' + organizationkey + '/organizations')
		}

		if( levs[1] && levs[1] == 'profile' && !levs[2] ){
			next('/' + organizationkey + '/profile')
		}

		if( levs[1] && levs[1] == 'help' && !levs[2] ){
			location.href = '/' + organizationkey + '/help'
			return false
		}

		if( levs[1] && levs[1] == 'help' && levs[2] ){
			//console.log('yy')
			//next('/' + organizationkey + '/help/' + levs[2] )
			location.href = '/' + organizationkey + '/help/' + levs[2]
			return false
		}

	}
	
	if( to.fullPath.indexOf("?select") > -1  ){	
		

		store.dispatch('selectOrganizations',{token: store.getters.token, key: to.params.orgname}).then(( response ) => {

			if( response.data.organization ){
				router.replace(to.path)
				store.dispatch('fetchOrglist', {token: store.getters.token}).then(() => {})
			}else{
				alert("Page has been updated, will reload")
				location.reload();
			}
		})
	}
	/*

	if( to.fullPath.indexOf("?reloadnoaccess") > -1   ){
		

		store.dispatch('selectOrganizations',{token: store.getters.token, key: to.params.orgname}).then(( response ) => {

			if( response.data.organization ){

				router.replace(to.path)
				store.dispatch('fetchOrglist', {token: store.getters.token}).then(() => {
					next()
				})

			}else{
				alert("Page has been updated, will reload")
				location.reload();
			}

		})
	}*/

	
//possible 403 error fix

/*
	if( usertoken && to.params.orgname && to.params.orgname != '' && to.params.orgname != organizationkey ){

		store.dispatch('fechtOrganization', {token: usertoken, key: to.params.orgname }).then(() => {

			store.dispatch('selectOrganizations',{token: store.getters.token, key: to.params.orgname}).then(( response ) => {

						if( response.data.organization ){
							router.replace(to.path)
							store.dispatch('fetchOrglist', {token: store.getters.token}).then(() => {})
						}else{
							alert("Page has been updated, will reload")
							location.reload();
						}
					})

		}).catch(() => {
			next('/403')
		})

	}
*/



	if( errpages.indexOf(to.path) > -1 ) {

		next()

	}else{


		store.dispatch('record', to)
		

		if (typeof to.params.unique_name !== 'undefined') {
			to.meta.title = to.meta.title + ' ' + to.params.unique_name
		}
			
		let resolver = router.resolve(to.path).route.name

		


		// checks for auth

		if( usertoken == '' ){
			usertoken = localStorage.token
		}


		if ( usertoken && usertoken != '' ) {

			if (whiteListAuth.indexOf(to.path.toLowerCase()) !== -1  && resolver !== '404' ) {

				next()

			} else {

			store.dispatch('fecthUserDetails', usertoken).then( function(response){
				
					if( response.data.ok ){                    

						localStorage.token = usertoken
						//localStorage.userdetails = JSON.stringify( response.data.user )

						if( response.data.user.organization ){
							cookie.set('organizationkey', response.data.user.organization.key); 
						}
						
				        next()

					    }else{

					    	cookie.set('usertoken',''); 
					    	localStorage.token = ""
					    	next("/sign-out")

					    }

					})
					.catch(error => console.log(error))
					.finally( () => {

					})
				}

			}else{

				if( levs[1] == 'sign-in-sso' || levs[1] == 'ssosignin' ){

					next()

				}else{


					if(whiteList.indexOf(to.path.toLowerCase()) == -1) {
						if(resolver == '400' || resolver == '401' || resolver == '403' || resolver == '404'){
							console.log('resolver')
							next('/'+resolver)
						}else{
								next('/sign-in?redirect='+ encodeURIComponent(to.fullPath) )
							}
						} 
					else {
						next()
					}

				}



			}

		}
	})


// var supportbrowser = function(){
// 	var ua = window.navigator.userAgent;
//     var msie = ua.indexOf("MISE");


// 	return msie

// }


