/* eslint-disable */
import {CognitoAuth, StorageHelper} from 'amazon-cognito-auth-js';
//import IndexRouter from '../router/index';
import IndexRouter from './router'
//import UserInfoStore from './user-info-store';
//import UserInfoApi from './user-info-api';

import Cognitosettings from '@/store/cognito-settings';
import UserInfoStore from '@/store/cognito-info-store';
import Signinapi from '@/services/api/signin'


import store from '@/store'
import cookie from 'vue-cookie'

import uuid from 'uuid'

const CLIENT_ID = Cognitosettings.state.clientid;
const APP_DOMAIN = Cognitosettings.state.appdomain;
const REDIRECT_URI = Cognitosettings.state.redirecturi;
const USERPOOL_ID = Cognitosettings.state.userpool;
const REDIRECT_URI_SIGNOUT = Cognitosettings.state.signouturi;
const APP_URL = Cognitosettings.state.appurl;

var authData = {
    ClientId : CLIENT_ID, // Your client id here
    AppWebDomain : APP_DOMAIN,
    TokenScopesArray : ['openid', 'email', 'profile'],
    RedirectUriSignIn : REDIRECT_URI,
    RedirectUriSignOut : REDIRECT_URI_SIGNOUT,
    UserPoolId : USERPOOL_ID,
}

var auth = new CognitoAuth(authData);

auth.userhandler = {
    onSuccess: function(result) {

        var self = this

        //console.log("On Success result", result);

        UserInfoStore.setLoggedIn(true);

        var authuser = auth.getSignInUserSession();

        Signinapi.Flowetoken( authuser.idToken.payload.zoneinfo ).then(response => {

            var xtoken = cookie.get('usertoken')

            console.log( xtoken ,"zz")
            console.log( authuser.idToken.payload ,"zz")
            console.log( response.data.user ,"zz")


            if( response.data.user ){

                var usertoken = response.data.user.uuid
                var orgkey = response.data.org.key
                cookie.set('usertoken', usertoken);
                cookie.set('signinmethod', "saml");

                var altsignin = 0


                if( response.data.user.credential && response.data.user.credential.password && response.data.user.credential.password != "" ){      
                    altsignin += 1
                }else if ( response.data.user.signingoogle && response.data.user.signingoogle != "" ){                    
                    altsignin += 1
                }

                if( altsignin == 0 ){
                    IndexRouter.push( '/set-password?eid='+usertoken+'&uid='+authuser.idToken.payload.zoneinfo+'&email='+authuser.idToken.payload.email )
                }else{
                    IndexRouter.push( '/'+orgkey+'/projects' )  
                }

                
                
                          
            }else{
//                IndexRouter.push( '/sign-in-sso?error=usernotmember' )
            }






        })

        /*

        var jwt = Signinapi.getjwtUserInfo()

        jwt.then( function(response){

            store.dispatch('setSamldata', response)

            var signinmethod = cookie.get('signinmethod');

            if ( signinmethod != "saml" ) {

                var info = response

                var email = info.email
                var firstname = info.given_name
                var lastname = info.family_name
                var sub = info.sub

                console.log( info , "info")


                Signinapi.Flowetoken( { "idp":"", "sub":sub, "email":email.toLowerCase() } ).then(response => {  

                    if( response.data.ok ){
                        var usertoken = response.data.response
                        cookie.set('usertoken', usertoken);
                        cookie.set('signinmethod', "saml");
                        IndexRouter.push( '/boards' )
                    }else{
                        IndexRouter.push( '/' )
                    }


                })

            } 

        })     */   
        
    },
    onFailure: function(err) {

    	console.log( err , "zzzzzz")

        var signinmethod = cookie.get('signinmethod');

        UserInfoStore.setLoggedOut();
        cookie.set('signinmethod', "");
        store.dispatch('setSamldata', {})

       /// if ( signinmethod == "saml" ) {
            IndexRouter.push( '/sign-in-sso?error=signinerror' )
       // }else{
        //    IndexRouter.push( '/sign-in-sso' )
       // }


        //IndexRouter.push( '/' )
        //IndexRouter.go({ path: '/error', query: { message: 'Login failed due to ' + err } });
    }
};

auth.useCodeGrantFlow();

function getUserInfoStorageKey(){
    var keyPrefix = 'CognitoIdentityServiceProvider.' + auth.getClientId();
    var tokenUserName = auth.signInUserSession.getAccessToken().getUsername();
    var userInfoKey = keyPrefix + '.' + tokenUserName + '.userInfo';
    return userInfoKey;
}

var storageHelper = new StorageHelper();
var storage = storageHelper.getStorage();
export default{
    auth: auth,
    login(){
        auth.getSession();
    },
    logout(){
        if (auth.isUserSignedIn()) {
            var userInfoKey = this.getUserInfoStorageKey();
            auth.signOut();

            storage.removeItem(userInfoKey);
        }
    },
    getUserInfoStorageKey,

}